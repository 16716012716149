import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import GridItem from "../../components/gridItem"
import { graphql } from "gatsby"
import { useLangContext } from "../../context/langContext"

const AccessPage = ({ data }) => {
    const { lang } = useLangContext()

    return (
        <Layout activeMenuId={6}>
            {data?.wpPage?.translations.map(translate => {
                return translate?.blocks.map((item, i) => {
                    switch (item?.name) {
                        case "acf/cover-main":
                            const coverMain = item?.coverMain
                            return (
                                <React.Fragment key={i}>
                                    <Seo title={coverMain?.title} />
                                    <PageHeader
                                        title={coverMain?.title}
                                        subTitle={coverMain?.text}
                                        image={
                                            coverMain?.img?.mediaItemUrl
                                        }
                                        breadcrumbs={[
                                            {
                                                label: coverMain?.title,
                                                link: null,
                                            },
                                        ]}
                                    />
                                </React.Fragment>
                            )
                        case "acf/text-w-col-cards":
                            const textWColCards = item?.textWColCards
                            return (
                                <div key={i} className="mb-4">
                                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                                        {textWColCards?.cards.map(
                                            (card, cardI) => (
                                                <GridItem
                                                    key={cardI}
                                                    bgImage={
                                                        card?.img
                                                            ?.mediaItemUrl
                                                    }
                                                    label={card?.title}
                                                    link={`/${lang}${card?.link?.url}`}
                                                />
                                            )
                                        )}
                                    </div>
                                </div>
                            )
                    }
                })
            })}
        </Layout>
    )
}

export const query = graphql`
    {
        wpPage(slug: { eq: "access" }) {
            translations {
                blocks {
                    name
                    ... on WpAcfCoverMainBlock {
                        coverMain {
                            img {
                                mediaItemUrl
                            }
                            text
                            title
                        }
                    }
                    ... on WpAcfTextWColCardsBlock {
                        textWColCards {
                            cards {
                                title
                                link {
                                    url
                                }
                                img {
                                    mediaItemUrl
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default AccessPage
