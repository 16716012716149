import React, { useState } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import { motion } from "framer-motion"
import * as styles from "../styles/blocks.module.css"
import defaultImg from "../images/grid-img-default.jpg"

const GridItem = ({
    height,
    bgImage = defaultImg,
    label,
    link,
    isExternalLink,
}) => {
    const [scale, setScale] = useState(1)
    const gridClass = classnames({
        [styles.gridItem]: !height,
        [styles.fixedGridItem]: height,
    })
    let style = {}
    if (height) {
        style = {
            height,
        }
    }
    const zoomIn = () => setScale(1.15)
    const zoomOut = () => setScale(1)
    return (
        <div
            className={gridClass}
            style={style}
            onMouseEnter={zoomIn}
            onMouseLeave={zoomOut}
        >
            <motion.div
                animate={{ scale }}
                transition={{ type: "tween" }}
                className={styles.gridItemContent}
                style={{
                    backgroundImage: `url(${bgImage})`,
                }}
            />
            {isExternalLink ? (
                <a href={link} target="_blank" rel="noreferrer">
                    {label}
                </a>
            ) : (
                <Link to={link}>{label}</Link>
            )}
        </div>
    )
}

export default GridItem
