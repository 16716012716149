import * as React from "react"
import { Link } from "gatsby"
import * as styles from "../styles/pageheader.module.css"
import { useLangContext } from "../context/langContext"

const PageHeader = ({
    title,
    subTitle,
    image,
    breadcrumbs,
    hasUnderline,
    children,
}) => {
    const { lang } = useLangContext()
    const bgStyle = {
        backgroundImage: image ? `url(${image})` : "none",
        backgroundSize: "cover",
    }
    return (
        <>
            <div className={styles.wrap} style={bgStyle}></div>
            {breadcrumbs && breadcrumbs.length > 0 && (
                <div
                    className={`hidden px-4 lg:block ${styles.breadcrumbs}`}
                >
                    <div className="container mx-auto">
                        <ul>
                            <li>
                                <Link to={"/"}>
                                    {lang === "en" ? "Home" : "Beranda"}
                                </Link>
                            </li>
                            {breadcrumbs.map((item, i) => {
                                return (
                                    <li key={`b-${i}`}>
                                        {item.link ? (
                                            <Link to={item.link}>
                                                {item.label}
                                            </Link>
                                        ) : (
                                            <span>{item.label}</span>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            )}
            <div className="px-4">
                {children ? (
                    <div className="my-12">{children}</div>
                ) : (
                    <div className="my-12">
                        <div className={`mx-auto ${styles.titleArea}`}>
                            <h1 className="font-serif text-center text-5xl lg:text-7xl mb-4">
                                {title}
                            </h1>
                            {subTitle && (
                                <p className="text-center text-base lg:text-xl">
                                    {subTitle}
                                </p>
                            )}
                        </div>
                        {hasUnderline && (
                            <span className={styles.underline} />
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default PageHeader
